import React, { useState } from "react";
import ContactForm from "../heavyside/ContactForm";
import MapContact from "../map-contact";
import Brandmark from "./Brandmark";
import CloseCross from "./CloseCross";
import AccordionItem from "../accordion/accordion-item";


const ContactModal = (props) => {

  const contactTitle = props.contactTitle;

  const [activeIndex, setActiveIndex] = useState(null);
  const [activeMap, setActiveMap] = useState(null);

  const handleItemClick = (index, longlat) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    setActiveMap(longlat);
  };

  const longlatArray = props.contactData
    .filter((contact) => contact.longlat)
    .map((contact) => contact.longlat);


  return (
    <div
      className={`fixed inset-0 w-full h-full overflow-y-scroll bg-black z-[40] text-white transition-opacity duration-300 ${props.modalOpen ? `opacity-100` : `opacity-0 pointer-events-none modal-close`
        }`}
    >
      <div className="container-theme">
        <div className="grid lg:grid-cols-3 relative pt-[170px] lg:py-[45px] lg:gap-x-[60px] gap-y-[95px] lg:gap-y-[0px]">
          <div

            className="lg:col-span-2 lg:h-full object-cover order-last lg:order-first aspect-[330/580] lg:aspect-auto pb-[45px] lg:pb-0 hidden lg:block"
          >
            {activeMap && longlatArray.map((longlat, index) => {
              if (index !== activeIndex) return null;
              return (
                <MapContact key={index} longLat={longlat} />
              );
            })}
          </div>

          <div>
            <h2 className="h2 font-bold tracking-wider pb-[42px] lg:pb-0 text-2xl !leading-[1.12]" dangerouslySetInnerHTML={{ __html: contactTitle }}>
            </h2>

            <div className="grid">
              {props.contactData && props.contactData.length > 0 && (
                <div className="space-y-10 my-16 lg:my-24 order-last lg:order-none">
                  {
                    props.contactData.map((contact, index) => {
                      return (
                        <AccordionItem
                          key={index}
                          title={contact.title}
                          officeName={contact.officeName}
                          officeId={contact.officeId}
                          address={contact.address}
                          phone={contact.phone}
                          email={contact.email}
                          map={contact.longlat}
                          isOpen={activeIndex === index}
                          onClick={() => handleItemClick(index, contact.longlat)}
                        />
                      );
                    })
                  }

                </div>
              )}

              <div className="order-first lg:order-none"><ContactForm /></div>
            </div>
          </div>

          <button className="absolute right-[0px] top-[60px] w-[30px] h-[30px] hidden lg:block">
            <CloseCross onClick={() => props.setModalOpen(false)} />
          </button>
        </div>
      </div>
      <div className="lg:hidden absolute inset-0 grid grid-cols-6 py-[42px] h-0 container-nav">
        <div></div>
        <div className="col-span-4 flex items-center justify-center">
          <button
            onClick={() => props.setModalOpen(!props.modalOpen)}
            type="button"
            className="w-[223px] h-auto lg:w-[227px] flex items-center justify-center pt-1 lg:pt-0"
          >
            <Brandmark />
          </button>
        </div>
        <div className="justify-end text-right flex lg:hidden items-center">

          <button
            onClick={() => props.setModalOpen(!props.modalOpen)}
            className="w-[20px] h-[20px] relative"
          >
            <span
              className={`absolute inset-0  transition-opacity duration-300 ${!props.modalOpen ? `opacity-0` : ``
                }`}
            >
              {" "}
              <CloseCross />
            </span>
          </button>
        </div>
      </div>

    </div>
  );
};

export default ContactModal;
