// map.js
import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

const mapContainerStyle = {
  width: "100%",
  height: "100%",
}

const MapContact = ({ longLat }) => {
  const mapContainerRef = useRef(null)

  const [map, setMap] = useState(null)

  //   const longLat = isNewZealand ? [174.7645, -36.8509] : [144.9961971, -37.8398392];

  useEffect(() => {
    const token = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;
    if (token) {
      mapboxgl.accessToken = token;

      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/dark-v10",
        center: longLat,
        zoom: 15,
      });


      // const map = new mapboxgl.Map({
      //   container: mapContainerRef.current,
      //   accessToken: MAPBOX_TOKEN,
      //   style: "mapbox://styles/mapbox/dark-v10",
      //   // Empire State Building [lng, lat]
      //   center: longLat,
      //   zoom: 15,
      // })
      setMap(map)

      // add marker

      const marker = new mapboxgl.Marker({ scale: 1.25, color: "#ffffff" })

      // if (isNewZealand == false){
      //   marker.setLngLat(longLat).addTo(map)
      // }
      marker.setLngLat(longLat).addTo(map)

      const resizeObserver = new ResizeObserver(() => {
        map.resize()
      })

      resizeObserver.observe(mapContainerRef.current)

      return () => {
        map.remove()
        resizeObserver.disconnect()
      }
    }
    else {
      console.error("Mapbox access token is not set");
    }
  }, [])

  return <div ref={mapContainerRef} style={mapContainerStyle} />
}

export default MapContact
